"use client"

import dynamic from "next/dynamic"
import type { ReactNode } from "react"
import { AppRouterCacheProvider } from "ui/dist/utils/AppRouterCache"

const ReactQuery = dynamic(() => import("../AuthTrpcAndReactQuery"), {
  ssr: false,
})

export interface CustomTheme {
  children: ReactNode
}

export default function AppProvider({ children }: CustomTheme) {
  return (
    <AppRouterCacheProvider options={{ enableCssLayer: true }}>
      <ReactQuery>{children}</ReactQuery>
    </AppRouterCacheProvider>
  )
}
