import(/* webpackMode: "eager" */ "/app/apps/web-client/app/_providers/AppProvider/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web-client/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Favicon"] */ "/app/apps/web-client/utils/favicon.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_xqvvitlnxtruf5np6cijv6gywy/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_xqvvitlnxtruf5np6cijv6gywy/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.49.1_babel-plugin-_xqvvitlnxtruf5np6cijv6gywy/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["InitColorSchemeScript"] */ "/app/packages/ui/dist/styles/index.js");
